import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as Projects from "../components/Projects"

const ProjetoPage = ({ transitionStatus, entry, data }) => {
  const projects = data.allFile

  return (
    <Layout transitionStatus={transitionStatus} entry={entry}>
      <SEO title="Projetos" />
      <Projects.Title transitionStatus={transitionStatus} />
      <Projects.ProjectsList
        projects={projects}
        transitionStatus={transitionStatus}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { absolutePath: { regex: "/projectsImages/" } }) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 500, maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
          id
        }
      }
    }
  }
`

export default ProjetoPage
