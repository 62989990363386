import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"

export const Title = ({ transitionStatus }) => {
  const titleOneRef = useRef(null)
  const titleTwoRef = useRef(null)

  useEffect(() => {
    const title = [titleOneRef.current, titleTwoRef.current]

    gsap.from(title, { y: 100, duration: 0.5, opacity: 0, stagger: 0.2 })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      const title = [titleOneRef.current, titleTwoRef.current]

      gsap.to(title, { y: -100, duration: 0.3, opacity: 0, stagger: 0.1 })
    }
  }, [transitionStatus])

  return (
    <Container>
      <Wrapper>
        <ProjectTitle>
          <span ref={titleOneRef}>Dê uma olhada nos</span>
          <span ref={titleTwoRef}>nossos projetos</span>
        </ProjectTitle>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  margin: 100px 0 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
`

const ProjectTitle = styled.h1`
  display: inline;
  align-items: center;
  position: relative;
  font-size: clamp(1.5rem, 8vw, 6rem);

  span {
    display: block;
  }
`
