import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Img from "gatsby-image"
export const ProjectsList = ({ transitionStatus, projects }) => {
  gsap.registerPlugin(ScrollTrigger)

  const wrapperRef = useRef(null)
  const projectsRef = useRef([])
  projectsRef.current = []

  useEffect(() => {
    const vect = [...wrapperRef.current.children]

    vect.map(item =>
      gsap.from(item, {
        scrollTrigger: item,
        y: 100,
        opacity: 0,
        delay: 0.5,
        duration: 0.7,
      })
    )
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      console.log(projectsRef.current)
      const vect = [...wrapperRef.current.children]

      vect.map(item =>
        gsap.to(item, {
          opacity: 0,
          duration: 0.7,
        })
      )
    }
  }, [transitionStatus])

  const addToRef = el => {
    if (el && !projectsRef.current.includes(el)) {
      projectsRef.current.push(el)
    }
  }

  return (
    <Container>
      <Wrapper ref={wrapperRef}>
        {projects.nodes.map(image => (
          <Img
            key={image.id}
            fluid={image.childImageSharp.fluid}
            alt="Imagem de projeto"
            ref={addToRef}
          />
        ))}
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 30px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  ${media.lessThan("medium")`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.lessThan("small")`
    grid-template-columns: 1fr;
  `}
`
